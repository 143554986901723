<!-- 
   取消订单 弹框
 -->
<template>
  <div class="Cancel">
    <el-dialog title="选择取消订单原因" :visible.sync="Open" class="Open" :before-close="handleClose" width="667px">
      <div class="Open-group">
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="找到更合适的服务"></el-checkbox>
          <el-checkbox label="虚假信息"></el-checkbox>
          <el-checkbox label="与卖家协商一致退款"></el-checkbox>
          <el-checkbox label="卖家服务态度恶劣"></el-checkbox>
          <el-checkbox label="虚假库存，长时间未开启服务"></el-checkbox>
          <el-checkbox label="与卖家产生争议，无法协调"></el-checkbox>
          <el-checkbox label="服务质量与描述不符"></el-checkbox>
          <el-checkbox label="卖家交付质量太差"></el-checkbox>
          <el-checkbox label="当前服务已完成"></el-checkbox>
          <el-checkbox label="其他"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="Open-textarea">
        <textarea class="clear" placeholder="请输入其他终止原因" maxlength="300" @input="textareaChange" v-model="textarea"></textarea>
        <span>{{textareaLength}}/300</span>
      </div>
      <div class="Open-orderdesc">订单取消后不可恢复，请与卖家充分沟通后再操作。</div>
      <div class="Open-operate">
        <div class="cancel">我在想想</div>
        <div class="payment">取消订单</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Open: false,
      checkList: [],
      textarea: "",
      textareaLength: 0,
    };
  },
  methods: {
    handleClose() {
      this.Open = false;
    },
    textareaChange(e) {
      // e.target.maxLength -
      this.textareaLength = e.target.textLength;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 30px 40px 50px !important;
}
/deep/ .el-checkbox {
  width: 50%;
  margin-right: 0;
  margin-top: 28px;
}

/deep/ .el-checkbox__label {
  font-size: 16px;
}
/deep/ .el-checkbox__inner {
  width: 17px;
  height: 17px;
}
/deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #fc3737;
}
/deep/ .el-checkbox__inner::after {
  top: 2px;
  left: 6px;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #fc3737;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #fc3737;
  border-color: #fc3737;
}
/deep/ .el-checkbox__inner:hover {
  border-color: #fc3737;
}
/deep/ .el-dialog__headerbtn {
  z-index: 66;
  top: 55px;
  right: 47px;
  font-size: 18px;
}
/deep/ .el-dialog__title {
  font-size: 24px;
  color: #000000;
  position: relative;
  top: 22px;
  left: 20px;
}
/deep/ .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fc3737;
}
/deep/ .el-dialog__headerbtn:focus .el-dialog__close {
  color: #fc3737;
}

.Open-operate {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  div {
    width: 120px;
    height: 46px;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    line-height: 23px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .cancel {
    border: 1px solid #999999;
    color: #999999;
  }
  .payment {
    background: #fc3737;
    color: #ffffff;
    margin-left: 18px;
  }
}
.clear {
  resize: none;
  width: 587px;
  height: 117px;
  background: #f5f5f5;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #e8e8e8;
  margin-top: 32px;
  padding: 15px;
  box-sizing: border-box;
  outline-color: #fc3737;
  &::placeholder {
    color: #999999;
  }
}
.Open-textarea {
  position: relative;
}
.Open-textarea span {
  position: absolute;
  bottom: 18px;
  right: 18px;
}
.Open-orderdesc {
  font-weight: 400;
  color: #999999;
  line-height: 16px;
  font-size: 14px;
  text-align: right;
  margin-top: 28px;
}
</style>