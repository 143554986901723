<!--
  需求 待支付
 -->
<template>
  <div class="Payment">
    <div class="PayMent-info banner">
      <div class="PayMent-order">
        <div class="PayMent-type">
          <div class="PayMent-flex">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"/></svg>
            <div class="TypeName">等待买家支付</div>
          </div>
          <div class="Order-dey">
            支付剩余时间：
            <countdown-timer :end-time="+moment(data.createTime).add(2, 'hours')"/>
          </div>
        </div>
        <div class="order-desc">该订单会保留2小时（从创建之日算起），2小时之后如果还未付款，系统将自动关闭该订单。</div>
      </div>
      <div class="order-info">
        <div class="order-user">
          <el-avatar :size="93" :src="$store.getters.getUser.avatarImg"></el-avatar>
          <div class="right-user">
            <div class="title">{{data.title}}</div>
          </div>
        </div>
        <div class="order-service">
          <div class="order-service-flex">
            <div class="Nmae">需求总价</div>
            <div class="Txt">￥{{ data.amount }}</div>
          </div>
          <div class="order-service-flex">
            <div class="Nmae">需付金额</div>
            <div class="Price Txt">
              <span>￥</span>
              {{ data.amount }}
            </div>
          </div>
        </div>
        <div class="order-service">
          <!--<div class="order-service-flex">-->
          <!--  <div class="Nmae">需求方昵称</div>-->
          <!--  <div class="Text Txt">{{data.providerName}}</div>-->
          <!--</div>-->
          <div class="order-service-flex">
            <div class="Nmae">创建时间</div>
            <div class="Text Txt">{{ moment(data.createTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
          </div>
        </div>
      </div>
      <div class="order-operate">
        <div class="operate">
          <!-- <div class="cancel" @click="$refs.Cancel.Open = true">取消订单</div> -->
          <!-- @click="$refs.Open.Open = true" -->
          <div class="cancel" @click="clickCancel">取消支付</div>
          <div class="payment" @click="submit">继续支付</div>
        </div>
      </div>
    </div>
    <!-- 支付弹框 -->
    <Open ref="Open" />
    <Cancel ref="Cancel" />
  </div>
</template>
<script>
import Open from "../Order/Open.vue";
import Cancel from "../Order/Cancel.vue";
import CountdownTimer from "@/components/FlipDown/countdownTimer.vue";
import moment from "moment";
export default {
  data() {
    return {
      data: "",
    };
  },
  components: {
    CountdownTimer,
    Open,
    Cancel,
  },
  created() {
    this.getDetail();
  },
  methods: {
    moment,
    // 获取需求详情
    getDetail() {
      let id = this.$route.query.id
      this.$axios
          .get(`/app-api/hiring/requirement/get`, {
            params: {
              id: id
            }
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.data = res.data.data
            }
          })
          .catch((err) => {
            this.$message.error("获取需求详情失败！" + err)
          });
    },
    submit() {
      if (this.data.payCenterOrderId) {
        this.goPayment(this.data.payCenterOrderId)
      } else {
        this.doSubmit()
      }
    },
    doSubmit() {
      let id = this.$route.query.id
      this.$axios
          .post(`/app-api/hiring/requirement-order/submit`, {
            requirementId: id
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.goPayment(res.data.data)
            }
          })
          .catch((err) => {
            this.$message.error("获取需求详情失败！" + err)
          });
    },
    // 去支付
    goPayment(id) {
      // 跳转到支付页面
      this.$router.push({
        name: "DemandBuy",
        params: {
          id: id,
          title: this.data.title,
          price: this.data.amount,
          time: this.data.createTime,
        },
      });
    },
    // 取消订单
    clickCancel() {
      this.$router.push({
        name: "PublishedDemandList",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.Payment {
  background: #f7f9fa;
  padding: 30px 0;
  .PayMent-info {
    height: 741px;
    background: #ffffff;
    box-shadow: 0px 4px 4px 1px rgba(204, 226, 237, 0.5);
    border-radius: 8px 8px 8px 8px;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
    .PayMent-order {
      width: 624px;
      margin: 20px 0;
      padding: 20px 0;
      background: #fc3737;
      border-radius: 8px;
      color: #ffffff;
      .order-desc {
        padding: 0 20px;
        font-weight: 400;
        line-height: 16px;
        font-size: 14px;
        margin-top: 17px;
      }
      .PayMent-type {
        padding: 0 20px;
        display: flex;
        align-items: center;
        .PayMent-flex {
          display: flex;
          align-items: center;
          svg {
            width: 20px;
            fill: #ffffff;
          }
        }
        .TypeName {
          font-weight: bold;
          line-height: 23px;
          font-size: 20px;
          margin-left: 5px;
        }
        .Order-dey {
          font-weight: 400;
          line-height: 14px;
          font-size: 12px;
          margin-left: 30px;
          display: flex;
          align-items: center;
          /deep/ .time-box {
            color: #ffffff;
          }
          /deep/ .time-unit {
            color: #ffffff;
          }
        }
      }
    }
  }
  .order-info {
    width: 624px;
    height: 404px;
    margin: 0 auto 0;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #d9d9d9;
    padding: 30px 50px;
    box-sizing: border-box;
    .order-user {
      display: flex;
      align-items: center;
      .right-user {
        margin-left: 20px;
        width: calc(100% - 140px);
        .title {
          font-weight: 400;
          color: #000000;
          line-height: 22px;
          font-size: 16px;
        }
      }
    }
    .order-service {
      border-top: 1px solid #d9d9d9;
      margin-top: 24px;
      .order-service-flex {
        display: flex;
        justify-content: space-between;
        margin-top: 26px;
        .Nmae {
          font-weight: 400;
          color: #999999;
          line-height: 19px;
          font-size: 16px;
        }
        .Txt {
          font-weight: 400;
          color: #333333;
          line-height: 19px;
          font-size: 16px;
        }
      }
    }
  }
  .order-operate {
    display: flex;
    justify-content: flex-end;
    margin: 40px auto 0;
    width: 624px;
    .operate {
      display: flex;
      margin-right: 53px;
      div {
        width: 120px;
        height: 46px;
        border-radius: 4px 4px 4px 4px;
        font-weight: 400;
        line-height: 23px;
        font-size: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .cancel {
        color: #999999;
        border: 1px solid #999999;
      }
      .payment {
        background: #fc3737;
        color: #ffffff;
        margin-left: 18px;
      }
    }
  }
}
</style>
